import C from "../../components/sidebar/StandardSidebar.module.css";

import { datetimeToDate } from "@warrenio/api-spec/conversion";
import { localeCompareBy } from "../../components/data/comparers.ts";
import { getFilteredItems } from "../../components/data/getFilteredItems.ts";
import { getSortedItems, type SimpleSortField } from "../../components/data/getSortedItems.ts";
import { EmptyItem, NoResults } from "../../components/sidebar/EmptyItem.tsx";
import { PriceBlock } from "../../components/sidebar/PriceBlock.tsx";
import { SidebarList, type SidebarListState } from "../../components/sidebar/SidebarList.tsx";
import { StandardBlock } from "../../components/sidebar/StandardSidebar.tsx";
import { cn } from "../../utils/classNames.ts";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { ResourceCreateLinks } from "../api/resourceCreateLinks.ts";
import { allAssignedVmsQueryAtom, type VirtualMachineWithAssigned } from "./joinAssignedQuery.ts";
import { vmLink } from "./links.ts";
import { MiniMetricChart } from "./monitor/MetricChart.tsx";
import { cpuMetric } from "./monitor/metricIds.ts";
import { OsImageIcon } from "./os/OsImageIcon.tsx";

const sortFields = [
    {
        id: "name",
        title: "Name",
        compare: localeCompareBy((i) => i.name),
    },
    { id: "created_at", title: "Created Date", getValue: (i) => datetimeToDate(i.created_at).getTime() },
    { id: "location", title: "Location", getValue: (i) => i.location },
    { id: "price", title: "Price", getValue: (i) => i.price.hourly },
] satisfies SimpleSortField<VirtualMachineWithAssigned>[];

export default function VmList() {
    return (
        <SidebarList
            data={(props) => <VmData {...props} />}
            title="Compute"
            sortFields={sortFields}
            actionButton={{
                title: "Create New Resource",
                action: ResourceCreateLinks.virtual_machine,
            }}
            listId="compute"
        />
    );
}

function VmData({ queryString, sortId, sortAsc }: SidebarListState) {
    const data = useSuspenseQueryAtom(allAssignedVmsQueryAtom);

    let vmItems = getFilteredItems(queryString, [...data.values()], (item) => [item.name]);
    vmItems = getSortedItems(sortFields, { sortId, sortAsc }, vmItems);

    const vmBlocks = vmItems.map((obj) => <VmBlock key={obj.uuid} item={obj} />);

    const empty = (
        <EmptyItem icon="jp-compute-cloud-icon">
            Create a new resource and get your services running in minutes.
        </EmptyItem>
    );

    return vmBlocks.length ? vmBlocks : queryString ? <NoResults /> : empty;
}

export function VmBlock({ item }: { item: VirtualMachineWithAssigned }) {
    const { location, name, uuid, os_name, status, assignedPublicIp, private_ipv4, price } = item;

    return (
        <StandardBlock link={vmLink(item)}>
            <div className={C.StandardBlockIcon}>
                <OsImageIcon os_name={os_name} className="size-1rem" />
            </div>

            <div className={cn(C.StandardBlockName, "text-ellipsis")}>
                <b className="text-ellipsis">{name}</b>
                <div className="text-ellipsis color-primary">
                    {assignedPublicIp?.address ?? private_ipv4} / {location}
                </div>
            </div>

            <div className={cn(C.StandardBlockChart, status === "running" && C.Filter)}>
                {status === "running" && <MiniMetricChart host={uuid} metric={cpuMetric} location={location} />}
                {status === "stopped" && <div className={C.Stopped} />}
            </div>

            <PriceBlock price={price} />
        </StandardBlock>
    );
}
